import * as React from "react";
import Typed from 'typed.js';
import { useEffect, useRef } from "react";

export default function TypedText() {
  // Create Ref element.
  const el = useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current!, {
	strings: ["Learn new things.", "Build cool projects.", "Join an awesome community.", "Impress interviewers.", "Learn. Build. Code."],
    backSpeed: 35,
    typeSpeed: 30,
    smartBackspace: true,
    startDelay: 200,
    backDelay: 1500,
    loop: true,
    loopCount: Infinity,
    });

    // Destropying
    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div>
      {/* Element to display typing strings */}
      <span ref={el}></span>
    </div>
  );
}

