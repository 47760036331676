import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ScopeCup.module.css";
import React from 'react';
const ScopeCup: FunctionComponent = () => {
  const navigate = useNavigate();

  const onPurpleinf1ImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHOMETextClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className={styles.scopeCup}>
      <img
        className={styles.colorstripe21Icon}
        alt=""
        src="/colorstripe2-1@2x.png"
      />
      <div className={styles.image8Parent}>
        <img className={styles.image8Icon} alt="" src="/Paul_Photo.png" />
        <img className={styles.image12Icon} alt="" src="/Ethan_Photo.png" />
        <img className={styles.image16Icon} alt="" src="/Jake_Photo.png" />
        <img className={styles.image14Icon} alt="" src="/adit-eboard.png" />
        <img className={styles.image9Icon} alt="" src="/stephanie-eboard.png" />
        <img className={styles.image13Icon} alt="" src="/gabby-eboard.png" />
        <img className={styles.image17Icon} alt="" src="/nathan-eboard.png" />
        <img className={styles.image15Icon} alt="" src="/hallie-eboard.png" />
        <div className={styles.div}>88888</div>
        <div className={styles.div1}>
          <p className={styles.p}>87862</p>
          <p className={styles.blankLine}>
            <b>&nbsp;</b>
          </p>
        </div>
        <div className={styles.div2}>
          <p className={styles.p}>90834</p>
          <p className={styles.blankLine}>
            <b>&nbsp;</b>
          </p>
        </div>
        <div className={styles.div3}>
          <p className={styles.p}>12344</p>
          <p className={styles.blankLine}>
            <b>&nbsp;</b>
          </p>
        </div>
        <div className={styles.xxXxContainer}>
          <p className={styles.p}>{`Paul+Stephanie`}</p>
          <p className={styles.blankLine}>
            <b>&nbsp;</b>
          </p>
        </div>
        <div className={styles.xxXxContainer1}>
          <p className={styles.p}>{`Jake+Nathan`}</p>
          <p className={styles.blankLine}>
            <b>&nbsp;</b>
          </p>
        </div>
        <div className={styles.xxXxContainer2}>
          <p className={styles.p}>{`Ethan+Gabby`}</p>
          <p className={styles.blankLine}>
            <b>&nbsp;</b>
          </p>
        </div>
        <div className={styles.xxXxContainer3}>
          <p className={styles.p}>{`Adit+Hallie`}</p>
          <p className={styles.blankLine}>
            <b>&nbsp;</b>
          </p>
        </div>
      </div>
      <img
        className={styles.scopecup3d1Icon}
        alt=""
        src="/scopecup3d-1@2x.png"
      />
      <img
        className={styles.scopecup3d2Icon}
        alt=""
        src="/scopecup3d-2@2x.png"
      />
      <div className={styles.menuBar}>
        <div className={styles.menuBarChild} />
        <img
          className={styles.purpleinf1Icon}
          alt=""
          src="/purpleinf-11@2x.png"
          onClick={onPurpleinf1ImageClick}
        />
        <div className={styles.aboutLearnCohort}>
          ABOUT LEARN COHORT FAQ SPONSOR SCOPE CUP
        </div>
        <div className={styles.home} onClick={onHOMETextClick}>
          HOME
        </div>
        <div className={styles.stats}>STATS</div>
        <div className={styles.login}>LOGIN</div>
      </div>
      <img
        className={styles.screenshot20230713At923}
        alt=""
        src="/screenshot-20230713-at-923-1@2x.png"
      />
      <a href="/">
      <div className={styles.menuBarParent}>
        <div className={styles.menuBar1}>
          <div className={styles.menuBarItem} />
          <div className={styles.aboutLearnCohort1}>
            ABOUT LEARN COHORT FAQ SPONSOR SCOPE CUP
          </div>
        </div>
        <div className={styles.home1}>HOME</div>
      </div>
      </a>
    </div>
  );
};

export default ScopeCup;
