import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import React from 'react';
import MainPage from "./pages/MainPage";
import { useEffect } from "react";
import ScopeCup from "./pages/ScopeCup";
import SponsorPage from "./pages/SponsorPage";
import CohortApplication from "./pages/CohortApplication";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "scope";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "scope";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }
    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <div className="idk">
      <head>
        <link rel="shortcut icon" href="silverInfinity.ico" type="image/x-icon?"/>
      </head>
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/cup" element={<ScopeCup/>} />
      <Route path="/sponsor" element={<SponsorPage/>} />
      <Route path="/apply" element={<CohortApplication device={window.innerWidth > 1000 ? "desktop" : "mobile"}/>} />
    </Routes>
    </div>
  );
}
export default App;
