import { FunctionComponent } from "react";
import styles from "./SponsorPage.module.css";
import React from 'react';
const SponsorPage: FunctionComponent = () => {
  return (
    <div className={styles.sponsorPage}>
      <img
        className={styles.globewShadow1Icon}
        alt=""
        src="/globew-shadow-11@2x.png"
      />
      <div className={styles.whiteScopeLogo}>
        <div className={styles.scopeOffWhite3d2}>
          <img
            className={styles.rectangleIcon}
            alt=""
            src="/rectangle8@2x.png"
          />
          <img
            className={styles.rectangleIcon1}
            alt=""
            src="/rectangle6@2x.png"
          />
          <img
            className={styles.rectangleIcon2}
            alt=""
            src="/rectangle9@2x.png"
          />
        </div>
      </div>
      <b className={styles.welcomeToScope}>
        Sponsorship Portal
      </b>
      <b className={styles.sponsorshipAmount50050}>
        Sponsorship Amount ($5.00-$5,000.00):
      </b>
      <b className={styles.pastSponsors}>Past Sponsors</b>
      <div className={styles.selectAnAmountContainer}>
        <p className={styles.selectAnAmount}>
          Select an amount and press the button below to get started.
        </p>
        <p className={styles.selectAnAmount}>
          All transactions are handled by Stripe.
        </p>
      </div>
      <div className={styles.ifYouOrContainer}>
        <p className={styles.selectAnAmount}>
          If you or your company has any specific questions about our recruiting
          opportunities and the many other benefits you can gain by sponsoring
          our organization, please reach out to our Director of Outreach here:
        </p>
      </div>
      <div className={styles.anayModyusc}>
        Anay Mody (anaymody@usc.edu)
      </div>
      <div className={styles.sponsorPageChild} />
      <div className={styles.sponsorPageItem} />
      <img className={styles.janeStreetLogoIcon} alt="" src="/jane-street-logo@2x.png" />
      <img className={styles.rippleLogoIcon} alt="" src="/ripple-logo@2x.png" />
      <img
        className={styles.microsoftLogoIcon}
        alt=""
        src="/microsoft-logo@2x.png"
      />
      <img className={styles.githubLogoIcon} alt="" src="/github-logo@2x.png" />
      <img className={styles.metaLogoIcon} alt="" src="/meta-logo1@2x.png" />
      <img className={styles.strivrLogoIcon} alt="" src="/strivr-logo@2x.png" />
      <img
        className={styles.qualcommLogoIcon}
        alt=""
        src="/qualcomm-logo@2x.png"
      />
      <div className={styles.purplescope}>
        <img
          className={styles.scopePurple3d1Icon}
          alt=""
          src="/scopepurple3d-1@2x.png"
        />
      </div>
      <div className={styles.sponsorEnteredAmount}>
        Sponsor *entered amount*
      </div>
      <a href="/">
      <div className={styles.menuBarParent}>
        <div className={styles.menuBar}>
          <div className={styles.menuBarChild} />
          <div className={styles.aboutLearnCohort}>
            ABOUT LEARN COHORT FAQ SPONSOR SCOPE CUP
          </div>
        </div>
        <div className={styles.home}>HOME</div>
      </div>
      </a>
    </div>
  );
};

export default SponsorPage;
