import React, {useState, useEffect, useRef} from 'react'
import { useForm } from 'react-hook-form';
import { GoogleSpreadsheet } from "google-spreadsheet";
import { JWT } from 'google-auth-library'

// import shadowImg from '../../public/globew-shadow-1@2x.png'

function CohortApplication({device}) {

	// shrink down to this scale when reviewing entire app
	const smallScale = 0.15;
	
	
	const clickCountClasses = ["", "first", "second", "third"]
	
	const [curField, setCurField] = useState(0);
	const [submitClickCount, setSubmitClickCount] = useState(0);
	const [isAppSubmitted, setIsAppSubmitted] = useState(false);
	const [isSubmitting, setIsAppSubmitting] = useState(false);
	const fieldBoxes = useRef([]);
	const submitRing = useRef(null);
	const formRef = useRef(null);
	const backToApp = useRef(null);
	const submitBtn = useRef(null);
	const fillingCircle = useRef(null);
	const containerRef = useRef(null);
	
	// figure out if the width or height is being used for vmin
	let smallerDimension = window.innerHeight;
	if (window.innerWidth < window.innerHeight)
	smallerDimension = window.innerWidth;

	// dimensions of entire page
	const pageWidth = smallerDimension * 10;
	const pageHeight = smallerDimension * 10;

	// check if any required fields were left empty
	function emptyRequiredFields() {
		const required = ["name", "pronouns", "email", "grade", "studies", "whyScope", "selfTeach", "yes", "friendship", "csExp", "prevApps"];
		const vals = getValues()
		
		for (const field in vals) {
			if(required.includes(field) && vals[field] === "")
				return true;
		}

		return false;
	}

	function saveEntries() {
		localStorage.setItem('entries', JSON.stringify(getValues()));
	}

	function loadEntries() {
		let entries;
		try {
			entries = JSON.parse(localStorage.getItem('entries'));
		} catch(err) {
			return;
		}

		for(const field in entries) {
			setValue(field, entries[field]);
		}
	}

	// zoom in or out to show the whole app
	function zoom(isZoomOut) {
		let newScale = 1.0;
		if(isZoomOut) {				
			newScale = smallScale;
			setIsAppSubmitting(true);
			console.log('dimensions', pageWidth, pageHeight);
			window.scrollTo({left: pageWidth / 2 - window.innerWidth / 2, top: pageHeight / 2 - window.innerHeight / 2, behavior: "instant"})
		}
		else {
			setIsAppSubmitting(false);
			if(submitClickCount !== 0)
				fillingCircle.current.classList.remove(clickCountClasses[submitClickCount])
			setSubmitClickCount(0);
		}

		submitRing.current.classList.toggle("visible")
		submitBtn.current.classList.toggle("visible")
		fillingCircle.current.classList.toggle("visible")


		// fieldBoxes.current.forEach(element => {
		// 	if(element === null)
		// 		return;
		// 	element.style.scale = newScale
		// });

		formRef.current.style.scale = newScale;

		if(!isZoomOut)
			setTimeout(() => scrollToField(curField), 400)
	}

	// moves the window to center over a given field
	function scrollToField(fieldNum, smooth=true) {
		console.log(smooth)
		fieldBoxes.current[fieldNum]//.querySelector('input, select')
			.scrollIntoView({behavior: smooth ? "smooth" : "instant", block: "center", inline: "center"})
	}

	// move to next field or zoom out at end
	const handleNextBtnClick = (finish=false) => {
		if(finish) {
			zoom(true);
			return;
		}

		setCurField(curField + 1);
		scrollToField(curField + 1);
	}

	// move to previous field
	const handleBackBtnClick = () => {
		if(curField === 0)
			return

		setCurField(curField - 1);
		scrollToField(curField - 1);
	}

	function handleSubmitClick() {
		let submitImmediately = false;

		if(device == "mobile") {
			if(window.confirm("Submit your application?"))
				submitImmediately = true;
			else return;
		}

		if (submitClickCount === 2 || submitImmediately) {
			submitForm();
			formRef.current.classList.toggle("submitted");
			setIsAppSubmitted(true)
		}

		if (submitClickCount !== 0)
			fillingCircle.current.classList.remove(clickCountClasses[submitClickCount]);
		fillingCircle.current.classList.add(clickCountClasses[submitClickCount + 1]);
		setSubmitClickCount(submitClickCount + 1);
	}

	function mobileSubmit(event) {
		
	}

	// focus on the first question to start
	useEffect(() => {
		loadEntries();
		console.log('jey')
		document.body.classList.toggle("no-scroll")
		scrollToField(0, false);

		const saveIntervalId = setInterval(saveEntries, 1000);

		return () => {
			document.body.classList.toggle("no-scroll")
			clearInterval(saveIntervalId);
		}
	}, [])

	const submitForm = async () => {
		formRef.current.submit();
	}

	// form stuff
	const { register, getValues, setValue, formState: { errors } } = useForm({mode: 'onBlur'});

	const scriptUrl = 'https://script.google.com/macros/s/AKfycbwATjFoXgA-gqZOYdOuTHsuIsJRNp62oJshZHENi8j-RTi7kW9ThYRVQvnqXf0t2SSN/exec'

	return (
	<div className={`CohortApplication ${device}`} ref={containerRef}>
		<form action="https://usebasin.com/f/5c71777507d3" method="POST" ref={formRef}>
			
			{/* decoration images */}
			{/* <img src="flame-blob.png" alt="gradient" className="decoration" id="flame-deco"/> */}
			{/* <img src="twisty-blob.png" alt="gradient" className="decoration" id="twisty-deco"/> */}
			<img src="cage_better.png" alt="people" className="decoration" id="cage-deco"/>
			<img src="friends.png" alt="two friends" className="decoration" id="friends-deco"/>
			<img src="party-pic.png" alt="salute" className="decoration" id="party-deco"/>
			{/* <img src="bird-blob.png" alt="gradient" className="decoration" id="bird-deco"/> */}
			{/* <img src="bird-blob.png" alt="gradient" className="decoration" id="stretched-bird-deco"/> */}
			<img src="view_better.jpg" alt="a view" className="decoration" id="view-deco"/>
			{/* <img src="gem-blob.png" alt="gradient" className="decoration" id="gem-deco"/> */}
			<img src="cake.JPG" alt="cake" className="decoration" id="cake-deco"/>
			<img src="YEAH.png" alt="three people having a great time" className="decoration" id="yeah-deco"/>
			<img src="speech.png" alt="three people giving a great speech" className="decoration" id="speech-deco"/>
			{/* <img src="twisty-blob.png" alt="gradient" className="decoration" id="twisty-deco2"/> */}
			{/* <img src="flame-blob.png" alt="gradient" className="decoration" id="flame-deco2"/> */}
			<img src="group.JPG" alt="gradient" className="decoration" id="group-deco"/>
			{/* <img src="gem-blob.png" alt="gradient" className="decoration" id="gem-deco2"/> */}

			{/* name */}
			<div className={`form-field ${device}`} id="name-field" ref={el => fieldBoxes.current.push(el)}>
				<div className="input-container">
					<label htmlFor="name">Name (first and last) </label>
					<input id="name" {...register("name")} />
					{errors.name && 
					<div className="errorMessage">{errors.name.message}</div>}
				</div>
				<div className="form-nav">
					<button type="button" className="btn-next" onClick={() => scrollToField(1)}>Next</button>
				</div>
			</div>

			{/* pronouns */}
			<div className={`form-field ${device}`} id="pronouns-field" ref={el => fieldBoxes.current.push(el)}>
				<div className="input-container">
					<label htmlFor="pronouns">What are your pronouns?</label>
					<input id="pronouns" {...register("pronouns")} />
					{errors.pronouns && 
					<div className="errorMessage">{errors.pronouns.message}</div>}
				</div>
				<div className="form-nav">
					<button type="button" className="btn-back" onClick={() => scrollToField(0)}>Back</button>
					<button type="button" className="btn-next" onClick={() => scrollToField(2)}>Next</button>
				</div>
			</div>


			{/* email */}
			<div className={`form-field ${device}`} id="email-field" ref={el => fieldBoxes.current.push(el)}>
				<div className="input-container">
					<label htmlFor="email">USC Email</label>
					<input id="email" {...register("email")} />
					{errors.email && 
					<div className="errorMessage">{errors.email.message}</div>}
				</div>
				<div className="form-nav">
					<button type="button" className="btn-back" onClick={() => scrollToField(1)}>Back</button>
					<button type="button" className="btn-next"  onClick={() => scrollToField(3)}>Next</button>
				</div>
			</div>

			{/* grade */}
			<div className={`form-field ${device}`} id="grade-field" ref={el => fieldBoxes.current.push(el)}>
				<div className="input-container">
					<label htmlFor="grade">Year</label>
					<select id="grade" {...register("grade")}>
						<option value="freshman">Freshman</option>
						<option value="sophomore">Sophomore</option>
						<option value="junior">Junior</option>
						<option value="senior">Senior</option>
					</select>
					{errors.grade && 
					<div className="errorMessage">{errors.grade.message}</div>}
				</div>
				<div className="form-nav">
					<button type="button" className="btn-back" onClick={() => scrollToField(2)}>Back</button>
					<button type="button" className="btn-next" onClick={() => scrollToField(4)}>Next</button>
				</div>
			</div>

			{/* majors and minors */}
			<div className={`form-field ${device}`} id="studies-field" ref={el => fieldBoxes.current.push(el)}>
				<div className="input-container">
					<label htmlFor="studies">What major(s) and minors (if applicable) are you pursuing?</label>
					<input id="studies" {...register("studies")}/>
				</div>
				{errors.studies && 
					<div className="errorMessage">{errors.studies.message}</div>}
				<div className="form-nav">
					<button type="button" className="btn-back" onClick={() => scrollToField(3)}>Back</button>
					<button type="button" className="btn-next"  onClick={() => scrollToField(5)}>Next</button>
				</div>
			</div>

			{/* why scope */}
			<div className={`form-field ${device}`} id="why-scope-field" ref={el => fieldBoxes.current.push(el)}>
				<div className="input-container">
					<label htmlFor="whyScope">Why would you like to join Scope? (About 200 words)</label>
					<textarea id="whyScope" {...register("whyScope")}></textarea>
				</div>
				{errors.whyScope && 
					<div className="errorMessage">{errors.whyScope.message}</div>}
				<div className="form-nav">
					<button type="button" className="btn-back" onClick={() => scrollToField(4)}>Back</button>
					<button type="button" className="btn-next" onClick={() => scrollToField(6)}>Next</button>
				</div>
			</div>
			
			{/* thing you taught yourself */}
			<div className={`form-field ${device}`} id="self-teach-field" ref={el => fieldBoxes.current.push(el)}>
				<div className="input-container">
					<label htmlFor="selfTeach">Describe a time when you taught yourself something that was <u>non-academic</u>. What were some of your takeaways? What would you do differently? (About 200 words)</label>
					<textarea id="selfTeach" {...register("selfTeach")}></textarea>
				</div>
				<div className="form-nav">
					<button type="button" className="btn-back" onClick={() => scrollToField(5)}>Back</button>
					<button type="button" className="btn-next" onClick={() => scrollToField(7)}>Next</button>
				</div>
			</div>

			{/* frequently used app or service */}
			<div className={`form-field ${device}`} id="app-change-field" ref={el => fieldBoxes.current.push(el)}>
				<div className="input-container">
					<label htmlFor="appChange">Think about your daily routine or something you do regularly. What app or service would you create to make this easier? (About 150 words)</label>
					<textarea id="appChange" {...register("appChange")}></textarea>
				</div>
				<div className="form-nav">
					<button type="button" className="btn-back" onClick={() => scrollToField(6)}>Back</button>
					<button type="button" className="btn-next" onClick={() => scrollToField(8)}>Next</button>
				</div>
			</div>

			{/* hobbies/passions */}
			<div className={`form-field ${device}`} id="hobbies-field" ref={el => fieldBoxes.current.push(el)}>
				<div className="input-container">
					<label htmlFor="hobbies">Talk about one of your hobbies or passions outside of computer science and tell us why you are interested in it. (About 150 words)</label>
					<textarea id="hobbies" {...register("hobbies")}></textarea>
				</div>
				<div className="form-nav">
					<button type="button" className="btn-back" onClick={() => scrollToField(7)}>Back</button>
					<button type="button" className="btn-next" onClick={() => scrollToField(9)}>Next</button>
				</div>
			</div>

			{/* best piece of advice */}
			<div className={`form-field ${device}`} id="advice-field" ref={el => fieldBoxes.current.push(el)}>
				<div className="input-container">
					<label htmlFor="advice">What is the best piece of advice you have received, and how has it influenced you as a person? (About 150 words)</label>
					<textarea id="advice" {...register("advice")}></textarea>
				</div>
				<div className="form-nav">
					<button type="button" className="btn-back" onClick={() => scrollToField(8)}>Back</button>
					<button type="button" className="btn-next" onClick={() => scrollToField(10)}>Next</button>
				</div>
			</div>

			{/* framework/webdev experience */}
			<div className={`form-field ${device}`} id="dev-exp-field" ref={el => fieldBoxes.current.push(el)}>
				<div className="input-container">
					<label htmlFor="devExp">Please briefly describe your experience with software frameworks and web/mobile development. (Note: prior experience is by no means a prerequisite)</label>
					<textarea id="devExp" {...register("devExp")}></textarea>
				</div>
				<div className="form-nav">
					<button type="button" className="btn-back" onClick={() => scrollToField(9)}>Back</button>
					<button type="button" className="btn-next" onClick={() => scrollToField(11)}>Next</button>
				</div>
			</div>


			{/* cs experience */}
			<div className={`form-field ${device}`} id="cs-exp-field" ref={el => fieldBoxes.current.push(el)}>
				<div className="input-container">
					<label htmlFor="csExp">Please list the CSCI/ITP (or other tech-related) coursework you are currently enrolled in or have most recently completed.</label>
					<textarea id="csExp" {...register("csExp")}></textarea>
				</div>
				<div className="form-nav">
					<button type="button" className="btn-back" onClick={() => scrollToField(10)}>Back</button>
					<button type="button" className="btn-next" onClick={() => scrollToField(12)}>Next</button>
				</div>
			</div>

			{/* prev apps */}
			<div className={`form-field ${device}`} id="prev-apps-field" ref={el => fieldBoxes.current.push(el)}>
				<div className="input-container">
					<label htmlFor="prevApps"><em>Not counting this application,</em> how many times have you previously applied to Scope?</label>
					<input id="prevApps" {...register("prevApps")}/>
				</div>
				<div className="form-nav">
					<button type="button" className="btn-back" onClick={() => scrollToField(11)}>Back</button>
					<button type="button" className="btn-next" onClick={() => scrollToField(13)}>Next</button>
				</div>
			</div>

			{/* race/ethnicity */}
			<div className={`form-field ${device}`} id="race-ethnicity-field" ref={el => fieldBoxes.current.push(el)}>
				<div className="input-container">
					<legend htmlFor="race-ethnicity">What is your race/ethnicity? Select as many fields as you wish. (This question is optional, and will not be visible when reviewing applications)</legend>
					<div><input type="checkbox" {...register("race-ethnicity")} id="aioan" name="ethnicity" value="American Indian or Alaskan Native"/><label htmlFor="aioan">American Indian or Alaskan Native</label></div>
					<div><input type="checkbox" {...register("race-ethnicity")} id="a" name="ethnicity" value="Asian"/><label htmlFor="a">Asian</label></div>
					<div><input type="checkbox" {...register("race-ethnicity")} id="boaa" name="ethnicity" value="Black or African American"/><label htmlFor="boaa">Black or African American</label></div>
					<div><input type="checkbox" {...register("race-ethnicity")} id="nhoopi" name="ethnicity" value="Native Hawaiian or Other Pacific Islander"/><label htmlFor="nhoopi">Native Hawaiian or Other Pacific Islander</label></div>
					<div><input type="checkbox" {...register("race-ethnicity")} id="w" name="ethnicity" value="White"/><label htmlFor="w">White</label></div>
					<div><input type="checkbox" {...register("race-ethnicity")} id="hol" name="ethnicity" value="Hispanic or Latino"/><label htmlFor="hol">Hispanic or Latino</label></div>
				</div>
				<div className="form-nav">
					<button type="button" className="btn-back" onClick={() => scrollToField(12)}>Back</button>
					<button type="button" className="btn-next" onClick={() => scrollToField(14)}>Next</button>
				</div>
			</div>

			{/* first generation college students */}
			<div className={`form-field ${device}`} id="first-gen-field" ref={el => fieldBoxes.current.push(el)}>
				<div className="input-container">
					<label htmlFor="first-gen">Are you a first generation college student?</label>
					<select id="first-gen" {...register("first-gen")}>
						<option value="No Answer">No Answer</option>
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</div>
				<div className="form-nav">
					<button type="button" className="btn-back" onClick={() => scrollToField(13)}>Back</button>
					<button type="button" className="btn-next" onClick={() => scrollToField(15)}>Next</button>
				</div>
			</div>

			{/* fun links */}
			<div className={`form-field ${device}`} id="link-field" ref={el => fieldBoxes.current.push(el)}>
				<div className="input-container">
					<label htmlFor="link">Supplemental link(s) [e.g. personal website, GitHub, something funny, all of the above, etc]:</label>
					<input id="link" {...register("link")}/>
				</div>
				<div className="form-nav">
					<button type="button" className="btn-back" onClick={() => scrollToField(14)}>Back</button>
					<button type="button" className="btn-next" onClick={() => scrollToField(16)}>Next</button>
				</div>
			</div>

			{/* other commitments */}
			<div className={`form-field ${device}`} id="commitments-field" ref={el => fieldBoxes.current.push(el)}>
				<div className="input-container">
					<label htmlFor="commitments">What are your anticipated commitments this semester?</label>
					<input id="commitments" {...register("commitments")} />
				</div>
				<div className="form-nav">
					<button type="button" className="btn-back" onClick={() => scrollToField(14)}>Back</button>
					<button type="button" className="btn-next" onClick={() => handleNextBtnClick(true)}>Finish</button>
				</div>
			</div>

			<button id="btn-finish" onClick={(e) => {e.preventDefault(); handleSubmitClick();}}>Finish</button>

			{/* submit */}
			<div id="submit-btn" ref={submitBtn} onClick={handleSubmitClick}>
				{!isAppSubmitted &&
					<h1>Click to submit<br/><span className="big-text">{3 - submitClickCount}x</span><br/></h1>}
				{isAppSubmitted &&
					<h1>Submitting...</h1>}
			</div>
			<div id="filling-circle" ref={fillingCircle}></div>
			<div id="submit-ring" ref={submitRing}> </div>
			
			{isSubmitting && !isAppSubmitted &&
			<div id="back-to-app" ref={backToApp} onClick={() => zoom(false)}>
				<h1>Return to Application</h1>
			</div>}
		</form>

		{isAppSubmitted &&
		<div id="submission-message">
			<h1>Thanks for applying!<br/>Check your email in the next day or so for a confirmation.</h1>
			<h2>Interview decisions will be emailed out on Sunday, September 10th.</h2>
		</div>}
		
		<script src="animations.js"></script>
	</div>
	)
}

export default CohortApplication